import React from "react"
import tw, { css } from "twin.macro"
import CLink from "../../components/cLink"
import Image from "../../components/imagev2"
// import Ellipsis from "@quid/react-ellipsis"
import Arrow from "../../components/vector/arrow"

const BlogCard = ({ entrada, t, categoriasPorRuta }) => {
  return (
    <div css={tw`pb-6`}>
      <div css={tw`relative`}>
        <CLink to={entrada.ruta}>
          <Image
            objectFit={"cover"}
            aspectRatioTablet={1.3}
            aspectRatioMobile={1.3}
            imageTablet={entrada.image}
            imageMobile={entrada.image_mobile}
            widths={["440px", "440px", "440px"]}
          />
        </CLink>
      </div>
      <ul css={tw`pt-2 pb-1 border-b border-gris4`}>
        {entrada.blog_categorias.map((categoriaRuta, index) => {
          return (
            <li css={tw`inline-block`} key={index}>
              {index !== 0 && (
                <span css={tw`text-obsssmall2 text-marron1 mx-1`}>/</span>
              )}
              <CLink
                css={tw`text-obsssmall2 text-marron1 hover:text-gris1 uppercase`}
                to={categoriasPorRuta[categoriaRuta].ruta}
              >
                {categoriasPorRuta[categoriaRuta].title}
              </CLink>
            </li>
          )
        })}
      </ul>
      <CLink
        css={tw`block pt-3 pb-4  text-gris1 dark:text-gris4  hover:text-marron1 `}
        to={entrada.ruta}
      >
        <div
          css={[tw`h-11 text-obmed3  uppercase font-bold overflow-hidden`, ellipsis, ellipsis2]}
        >
          {entrada.title}
        </div>
      </CLink>
      <div
        css={[tw`h-12 text-obsmall text-gris1 dark:text-gris4  overflow-hidden`, ellipsis, ellipsis3]}
      >
        {entrada.texto_blog_introduccion}
      </div>
      <CLink
        css={tw`block py-4 text-obmed2 text-marron1 uppercase font-semibold `}
        to={entrada.ruta}
      >
        {t?.blog?.readMore} <Arrow color={"#847360"} />
      </CLink>
    </div>
  )
}

const ellipsis = css`
  display: -webkit-box;

  -webkit-box-orient: vertical;
  overflow: hidden;
`
const ellipsis2 = css`
  -webkit-line-clamp: 2;
`
const ellipsis3 = css`
  -webkit-line-clamp: 3;
`
export default BlogCard
