
import * as React from "react"
import PropTypes from "prop-types"
import { useSpring, animated as a } from '@react-spring/web'
import tw from 'twin.macro';

const Arrow = ({ color, hover }) => {

  const containerStyle = useSpring({
    config: {
      // mass: 100,
      tension: 400,
      friction: 50,
    },
    marginLeft: hover ? "0px" : "10px",
  })
  return (
    <a.div
      css={tw`inline-block`}
      styles={containerStyle}
    >
        <svg width="30" height="13" viewBox="0 0 30 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <a.line x1="0" y1="6.5" x2="13" y2="6.5" stroke={color}/>
            <a.path d="M13 1L19 6.5L13 12" stroke={color} strokeMiterlimit="10" strokeLinejoin="round"/>
        </svg>
    </a.div>
  )
}

Arrow.propTypes = {
  color: PropTypes.string,
  hover: PropTypes.bool,
}

Arrow.defaultProps = {
  color: '#ffffff',
  hover: false,
}

export default Arrow
